import { Card, Table } from 'react-bootstrap';
import React from 'react';
import findLink from '../../containers/findLink';

const FieldOrProperty = ({ isField, data, linkList }) => {
  const title = isField ? 'Fields' : 'Properties';
  return (
    <>
      <h3 className={'pad'}>{title}</h3>
      {data.map((data, idx) => {
        return (
          <Card className={'card-margin'} border={'light'} key={idx}>
            <Card.Body>
              <Card.Title>{data.name}</Card.Title>
              <Card.Subtitle className={'description'}>
                {data.comment.split('\n').map(comment => <p>{comment}</p>)}
              </Card.Subtitle>
              <Table bordered className={'caption-top'}>
                <caption>Details</caption>
                <tbody>
                <tr>
                  <td className={'table-active'} style={{ width: '30%' }}>Field Value</td>
                  <td>{findLink(linkList, data.type)}</td>
                </tr>
                {isField && data.value.length !== 0
                  ? (
                    <tr>
                      <td className={'table-active'} style={{ width: '30%' }}>Default Value</td>
                      <td>{data.value}</td>
                    </tr>
                  )
                  : null}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      })
      }
    </>
  );
};

export default FieldOrProperty;
