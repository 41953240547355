import React from 'react';
import Method from './method';
import FieldOrProperty from './property';

const Interface = ({ methods, properties, link_list }) => {
    return (
        <div>
            {properties.length
                ? <FieldOrProperty isField={false} data={properties} linkList={link_list} />
                : null}
            {methods.length ? <Method methods={methods} linkList={link_list} /> : null}
        </div>
    );
};

export default Interface;
