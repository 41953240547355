import React from "react"
import { Table } from 'react-bootstrap';

const Enum = ({enumValues}) => {
  return (
    <div>
      <h3 className={'pad'}>Fields</h3>
      <Table responsive striped>
        <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th style={{width: '10%'}}>Value</th>
        </tr>
        </thead>
        <tbody>
        {enumValues.map((data) => (
          <tr key={data.value}>
            <td>{data.name}</td>
            <td>{data.comment}</td>
            <td>{data.value}</td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Enum
