import React from 'react';
import Prism from 'prismjs';
import { Card, Table } from 'react-bootstrap';
import getDataType from '../../containers/dataType';
import findLink from '../../containers/findLink';

class Constructor extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Prism.highlightAll();
  }

  render() {

    return (
      <div>
        <h3 className={'pad'}>Constructors</h3>
        {
          this.props.constructors.map((data, idx) => {
            let paramArr = [];
            data.param.map((p) => {
              paramArr.push(getDataType(p.type) + ' ' + p.name
                + (p.default_value.length ? (' = ' + ((p.default_value === 'empty string') ? '""' : p.default_value)) : ''));
            });
            const declaration = `${data.name}(${paramArr.join(', ')})`;
            const defaultRow = data.param.some(p => p.default_value.length);

            return (
              <Card className={'card-margin'} key={idx}>
                <Card.Body>
                  <Card.Subtitle className={'description'}>
                    {data.comment.split('\n').map((comment) => <p>{comment}</p>)}
                  </Card.Subtitle>
                  <pre style={{ margin: '15px 5px' }}><code className={'language-csharp'}>
                  {declaration}
                </code></pre>
                  {data.param.length
                    ? (<Table responsive className={'caption-top'}>
                      <caption>Parameters</caption>
                      <thead>
                      <tr>
                        <th style={{ width: '20%' }}>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        {defaultRow ? (<th>Default</th>)
                          : null}
                      </tr>
                      </thead>
                      <tbody>
                      {data.param.map((p, idx) => {
                        return (
                          <tr key={idx}>
                            <th>{p.name}</th>
                            <td>{findLink(this.props.linkList, p.type)}</td>
                            <td>{p.comment}</td>
                            {defaultRow ? (<td>{p.default_value}</td>) : null}
                          </tr>
                        );
                      })}
                      </tbody>
                    </Table>) : null}
                </Card.Body>
              </Card>
            );
          })
        }

      </div>
    );
  }
}

export default Constructor;
