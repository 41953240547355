import { Card, Table } from 'react-bootstrap';
import React from 'react';
import Prism from 'prismjs';
import getDataType from '../../containers/dataType';
import findLink from '../../containers/findLink';

class Method extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    return (
      <div>
        <h3 className={'pad'}>Methods</h3>
        {
          this.props.methods.map((data, idx) => {
            let paramArr = [];
            data.param.map((p) => {
              paramArr.push((p.type.includes('&') ? 'out ' : '')
                + getDataType(p.type) + ' '
                + p.name
                + (p.default_value.length ? (' = ' + ((p.default_value === 'empty string') ? '""' : p.default_value)) : ''));
            });
            const returnType = getDataType(data.returns.type);
            const declaration = `${returnType} ${data.name}(${paramArr.join(', ')})`;
            const defaultRow = data.param.some(p => p.default_value.length);

            return (
              <Card className={'card-margin'} key={idx}>
                <Card.Body>
                  <Card.Title>{data.name + '()'}</Card.Title>
                  <Card.Subtitle className={'description'}>
                    {data.comment.split('\n').map((comment) => <p>{comment}</p>)}
                  </Card.Subtitle>
                  <pre style={{ margin: '15px 5px' }}><code className={'language-csharp'}>
                  {declaration}
                </code></pre>
                  {data.param.length
                    ? (<Table responsive className={'caption-top'}>
                      <caption>Parameters</caption>
                      <thead>
                      <tr>
                        <th style={{ width: '20%' }}>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        {defaultRow ? (<th>Default</th>)
                          : null}
                      </tr>
                      </thead>
                      <tbody>
                      {data.param.map((p, idx) => {
                        return (
                          <tr key={idx}>
                            <th>{p.name}</th>
                            <td>{findLink(this.props.linkList, p.type)}</td>
                            <td>{p.comment}</td>
                            {defaultRow ? (<td>{p.default_value}</td>) : null}
                          </tr>
                        );
                      })}
                      </tbody>
                    </Table>) : null}

                  {data.returns.type.includes('System.Void') ? null : (
                    <Table bordered className={'caption-top'}>
                      <caption>Returns</caption>
                      <tbody>
                      <tr>
                        <td className={'table-active'} style={{ width: '30%' }}>Type</td>
                        <td>{findLink(this.props.linkList, data.returns.type)}</td>
                      </tr>
                      {data.returns.type === 'System.Void' ? null
                        : (
                          <tr>
                            <td className={'table-active'} style={{ width: '30%' }}>Description</td>
                            <td>{data.returns.comment}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>)
                  }
                </Card.Body>
              </Card>
            );
          })
        }
      </div>
    );
  }
}

export default Method;
