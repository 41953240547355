import React from 'react';
import Layout from '../components/layout';
import Interface from './api/interface';
import Enum from './api/enum';
import Class from './api/class';
import Delegate from './api/delegate';
import { StaticQuery } from 'gatsby';

class ApiDetail extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { details, version, assembly_name } = this.props.pageContext;
    let type = '';
    switch (details.type) {
      case 0:
        type = 'interface';
        break;
      case 1:
        type = 'enum';
        break;
      case 2:
        type = 'class';
        break;
      case 3:
        type = 'struct';
        break;
      case 4:
        type = 'delegate';
        break;
    }
    return (
      <StaticQuery query={graphql`
      query {
        allSitePage(filter: {path: {regex: "/api/"}}) {
          nodes {
            path
          }
        }
      }`
      } render={(query) => {
        const prefix = `/api/${assembly_name.toLowerCase()}@${version}`;
        const allUrlList = query.allSitePage.nodes.filter(({ path }) => path.includes(prefix));
        return (
          <Layout showSide
                  title={`${details.name}`}
                  version={version}
                  assembly_name={assembly_name}
                  path={details.name.toLowerCase()}>
            <h1 style={{ fontWeight: '700', wordBreak:'break-word' }}>
              {details.name.replace('+', '.')}
            </h1>
            <p className={'text-secondary'}>
              {type}<br />
              namespace : {details.name_space}
            </p>
            <div className={'description'} style={{wordBreak:'break-word'}}>
              {details.comment.split('\n').map((comment, idx) => (<p key={idx}>{comment}</p>))}
            </div>
            <div className={'bottom-line'} />
            <ShowDefinition {...details} link_list={allUrlList} />
          </Layout>
        );
      }
      }>
      </StaticQuery>
    );
  }
}

export default ApiDetail;

const ShowDefinition = ({ type, constructor_list, field_list, property_list, method_list, enum_value_list, link_list }) => {
  switch (type) {
    case 0:
      // interface
      return (<Interface methods={method_list} properties={property_list} link_list={link_list} />);
      break;
    case 1:
      // enum
      return (<Enum enumValues={enum_value_list} />);
      break;
    case 2:
    // class
    case 3:
      // struct
      return (<Class constructors={constructor_list} fields={field_list} properties={property_list} methods={method_list} link_list={link_list} />);
    case 4:
      // Delegate
      return (<Delegate methods={method_list} link_list={link_list} />);
      break;
  }
};
