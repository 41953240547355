import React from 'react';
import FieldOrProperty from './property';
import Method from './method';
import Constructor from './constructor'

class Class extends React.Component {
  render() {
    const { constructors, fields, properties, methods, link_list } = this.props;
    return (
      <>
        {constructors && constructors.length
          ? <Constructor constructors={constructors} linkList={link_list} />
          : null}
        {fields.length
          ? <FieldOrProperty isField={true} data={fields} linkList={link_list} />
          : null}
        {properties.length
          ? <FieldOrProperty isField={false} data={properties} linkList={link_list} />
          : null}
        {methods.length
          ? <Method methods={methods} linkList={link_list} />
          : null}
      </>
    );
  }
}

export default Class;
