import getDataType from './dataType';
import React from 'react';
import { Link } from 'gatsby';

export default function findLink(list, target) {
  const typename = target.toLowerCase().replace(/[\[\]]/g, '').split('.');
  const type = getDataType(target);
  if (type.includes('<')) {
    // Generic임
    const type_name = type.substring(0, type.indexOf('<'));
    const generic_list = type.substring(type.indexOf('<') + 1, type.indexOf('>')).split(', ');

    const link_list = generic_list.map((generic) => {
      const index = list.find(e => e.path.split('/').pop() === generic.split('.').pop().toLowerCase().replace(/[\[\]]/g, ''));
      return (index === undefined || generic.includes("Internal") ? generic : <Link to={index.path}>{generic.split('.').pop()}</Link>);
    })
    const length = link_list.length
    for (var i = 1; i < length; i++) {
      link_list.splice(i, 0, ', ')
    }

    return (<>
      {`${type_name}<`}
      {link_list.map(element => { return (element)})}>
    </>)
  }

  const index = list.find((e) => e.path.split('/').pop() === typename.slice(-1)[0].replaceAll('+', '-'));


  return (index === undefined ? type : <Link to={index.path}>{type.split('.').pop()}</Link>);

}
