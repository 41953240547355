import { Table } from 'react-bootstrap';
import React from 'react';
import findLink from '../../containers/findLink';

const Delegate = ({ methods, link_list }) => {
  return (
    <>
      <h3 className={'pad'}>Parameters</h3>
      <Table responsive>
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
          {methods[0].param.some(p => p.default_value.length) ? (<th>Default</th>) : null}
        </tr>
        </thead>
        <tbody>
        {methods[0].param.map((par) => {
          return (
            <tr key={par.name}>
              <td>{par.name}</td>
              <td>{findLink(link_list, par.type)}</td>
              <td>{par.comment}</td>
              {par.default_value.length ? (<td>{par.default_value}</td>) : null}
            </tr>
          );
        })
        }
        </tbody>
      </Table>

      <h3 className={'pad'}>Returns</h3>
      <Table bordered>
        <tbody>
        <tr>
          <td className={'table-active'} style={{ width: '30%' }}>Type</td>
          <td>{findLink(link_list, methods[0].returns.type)}</td>
        </tr>
        {methods[0].returns.type === 'System.Void' ? null
          : (
            <tr>
              <td className={'table-active'} style={{ width: '30%' }}>Description</td>
              <td>{methods[0].returns.comment}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Delegate;
